<script setup>   
    import { defineProps, computed } from 'vue';
    import { getOrCreateDataObject } from 'o365-dataobject';
    import ModalCreateLease from "lease.libraries.vue.components.CreateLease.vue";
    import { getOrCreateProcedure } from 'o365-modules';
    import IFrame from 'o365.vue.components.Iframe.vue';
    import { alert } from 'o365-vue-services';

    const props = defineProps({
        itemId: Number,
        stepId: Number,
        readOnly: Boolean
    }); 

    const procScopeItemAddLease = getOrCreateProcedure({ id: "procScopeItemAddLease", procedureName : "astp_Lease_ScopeItemAddLease"});

    const dsLATWorkflow = getOrCreateDataObject({
        id: 'dsLATWorkflow',
        viewName:  'aviw_Scope_Items',
        uniqueTable: 'atbv_Scope_Items',
        allowUpdate: true,
        selectFirstRowOnLoad: true,
        maxRecords: 1,
        whereClause: props.itemId ? `ID = ${props.itemId}` : "1=0",
        distinctRows: false,
        fields:[
            {name: "ID", type: "number" },
            {name: "OrgUnit_ID", type: "number" },
            {name: "Lease_ID", type: "number" },
            {name: "OrgUnit", type: "string" },
            {name: "OrgUnitName", type: "string" },
            {name: "OrgUnitTitle", type: "string" },
        ]
    });

    const dsLease = getOrCreateDataObject({
        id: 'dsLease',
        viewName: 'aviw_Lease_LeasesScopeItems',
        maxRecords: 1,
        distinctRows: false,
        disableAutoLoad: false,
        selectFirstRowOnLoad: true,
        whereClause: "Closed IS NULL",
        masterDataObject_ID: `dsLATWorkflow`,
        masterDetailDefinition: [{"masterField": "ID", "detailField": "ScopeItem_ID", "operator": "equals"}],
        fields:[
            { name: "ID", type: "number" },
            { name: "LesseeOrgUnit_ID", type: "number" },
            { name: "ScopeItem_ID", type: "number" },
            { name: "Name", type: "string" },
            { name: "Title", type: "string" }
        ]
    });

    const dsLeaseLkp = getOrCreateDataObject({
        id: 'dsLeaseLkp',
        viewName: 'aviw_Lease_Leases',
        maxRecords: 25,
        distinctRows: false,
        disableAutoLoad: true,
        selectFirstRowOnLoad: true,
        whereClause: "Closed IS NULL",
        fields:[
            { name: "ID", type: "string" },
            { name: "OrgUnit", type: "string" },
            { name: "Lessee", type: "string" },
        ]
    });

    const leaseSelected = (pRow) => {
        if(pRow.ID) {
            scopeItemAddLease(pRow.ID);
        }
    };

    const scopeItemAddLease = async(pLease_ID) => {
        try {
            await procScopeItemAddLease.execute( { ScopeItem_ID: props.itemId, Lease_ID: pLease_ID } );
            dsLease.load();
            alert( $t('Lease attached'), "success", { autohide : true } );
        }
        catch (_) {}
    };

    if(!props.readOnly) {
        dsLATWorkflow.load();
    }
    const vUrlTabs = computed(() => { return `/lease?hideNav=true&ID=${dsLease?.current.ID}&hideTabs=ContractElements,OrderItems,Documents,Workflows&activeTab=Lease` })
    const vUrl = computed(() => { return `/lease?ID=${dsLease?.current.ID}` })
</script>   

<template>
    <div v-if="!readOnly && dsLease.state._isLoaded">
        <div v-if="dsLease.current.ID">
            <a :href="vUrl" target="_blank"><i class="bi bi-box-arrow-up-right"></i> {{$t("Open lease in new tab")}}</a>
            <IFrame :src="vUrlTabs" ref="vIframe" style="height:100%;width:100%;min-height:600px;"/> 
        </div>
        <div v-else class="px-2 px-lg-3 py-1 py-lg-2 border" style="background-color: cornsilk;" v-if="!readOnly && dsLease.state._isLoaded">
            <div class="row">
                <ModalCreateLease ref="modalCreateLease" :ds="dsLease" @lease-added="() => dsLease.load()" :lesseeOrgUnit_ID="dsLATWorkflow.current.OrgUnit_ID" :lesseeOrgUnit="dsLATWorkflow.current.OrgUnit" 
                    :lesseeOrgUnitName="dsLATWorkflow.current.OrgUnitName" :lesseeOrgUnitTitle="dsLATWorkflow.current.OrgUnitTitle" :scopeItem_ID="dsLATWorkflow.current.ID"/>
                <a class="btn btn-outline-primary btn-sm" @click="() => $refs.modalCreateLease.show()">{{$t("New Lease")}}</a>
            </div>
            <div class="row">
                <label class="ms-0">{{$t("... or use existing lease")}}</label>
                <ODataLookup class="form-control form-control-sm" :title="$t('Lease')" :data-object="dsLeaseLkp" :value="dsLease.current.OrgUnit" :bind="leaseSelected">
                    <OColumn field="ID" width="80" :header-title="$t('ID')" :header-name="$t('ID')" />
                    <OColumn field="OrgUnit" width="400" :header-title="$t('OrgUnit')" :header-name="$t('OrgUnit')" />
                    <OColumn field="Lessee" width="400" :header-title="$t('Lessee')" :header-name="$t('Lessee')" />
                </ODataLookup>
            </div>
        </div>
    </div>
</template>